import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"
import { formatGraphQLImagesToGallery } from "../utils/gallery"
import { Flex, Box, Heading } from "rebass"
import ShareButtons from "../components/shareButtons"
import Divider from "../components/divider"
import {
  BootsToFreedomAmazonBuyButton,
  BuyCallToAction,
  Paragraph,
  Testimonial,
} from "../components"

const BootsToFreedom = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      bookCover: file(
        relativePath: { eq: "boots-to-freedom-front-cover-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nepalMap: file(relativePath: { eq: "nepal-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      openGraphImage: file(
        relativePath: { eq: "boots-to-freedom-og-image.png" }
      ) {
        childImageSharp {
          fixed(width: 1146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gallery: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "boots-to-freedom" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const social = {
    description:
      "Now in my seventies, I found myself trekking in Australia, Nepal, and Spain, both with groups and alone",
    title: "Boots to Freedom by Claude Tranchant",
    image: `${data.site.siteMetadata.siteUrl}${data.openGraphImage.childImageSharp.fixed.src}`,
    url: `${data.site.siteMetadata.siteUrl}/boots-to-freedom`,
  }

  const testimonials = [
    {
      by: "M. Bella",
      testimony:
        "Claude Tranchant's second book Boots to Freedom traces the challenging treks she undertook in Australia, Nepal and France/Spain aged in her seventies. The difficulties she encountered reflect the challenges in her life, and how she was determined to overcome them. The book is written with humour, deep insight, and a real connection with the people she meets on her travels. It is a great read for anyone contemplating such long journeys on foot, or simply for those wanting to improve their lot in life. Well done, Claude!",
    },
    {
      by: "J. Amos",
      testimony:
        "I just finished Boots to Freedom! Loved every minute walking with you through your amazing journeys! So inspiring! I loved your honesty, humor, and tenacity! I am a few years behind you in age as I am 67, but I found your words and your loving spirit so encouraging. From your book I was encouraged to know, that with the right attitude..we CAN still find our WAY, and don't need to be defined by age! Thank you for sharing your journey! Loved every minute walking beside you through your beautiful words!",
    },
    {
      by: "R. Wilson",
      testimony:
        "Claude Tranchant's second book Boots to Freedom is inspirational and unique!  Claude is a writer who describes her journeys vividly and shares beautifully from her heart, you can actually feel her pain as it is so honestly shared and you can feel yourself walking right along beside her.  Her writing about her Camino journey and other walking experiences in this book are truthful and honest about how she ‘finds' herself.  By sharing her experiences and feelings she helps people break down walls and channels truth and spreads light wherever she goes in a beautiful spiritual way. Her writing of these experiences helps readers to believe in themselves and to look for miracles that surround them, it helps them to free themselves, as she has done.  She cares about the beauty and sanctity of life yet she asks for nothing in return, she gives from the depths of her being without any expectation or need, to receive anything back. I don't think there's anyone who wouldn't benefit from reading this book!",
    },
  ]

  return (
    <Layout>
      <SEO
        title="Boots to Freedom by Claude Tranchant"
        description={
          "To my amazement, after crossing France and Spain, alone, at 64 years of age, I discovered I had feet and caught the trekking bug.\n" +
          "Years later, in my seventies, through various circumstances, I found myself trekking in Australia, Nepal, and Spain, both with groups and alone, at different times. During these treks, I had more challenges to go through physically and this was quite understandable; however, to my surprise, there were emotional challenges as well."
        }
        canonicalUrl={social.url}
        openGraph={{
          namespace: "og: http://ogp.me/ns# book: http://ogp.me/ns/book#",
          meta: {
            description: social.description,
            type: "books.author",
            image: social.image,
            book: {
              author: `${data.site.siteMetadata.siteUrl}/`,
              isbn: ["9780648867005", "9780648867012"],
              release_date: "2020-11-14",
              tag: [
                "Boots to Freedom",
                "Travel",
                "Nepal",
                "Spain",
                "Australia",
                "Larapinta",
              ],
            },
          },
        }}
      />
      <Flex flexWrap="wrap">
        <Box
          width={[1, 1, 3 / 4]}
          sx={{
            textAlign: ["center", "center", "left"],
          }}
        >
          <Heading fontSize={[5, 6]}>Boots to Freedom</Heading>
          <Heading fontSize={[2, 3]} mb={3}>
            by Claude Tranchant
          </Heading>
        </Box>
        <Box
          width={[1, 1, 1 / 4]}
          sx={{
            display: "flex",
            justifyContent: ["center", "center", "flex-end"],
            alignItems: "center",
          }}
        >
          <BuyCallToAction
            amazonButton={<BootsToFreedomAmazonBuyButton />}
            data-testid={"buy-call-to-action"}
          />
        </Box>
      </Flex>
      <Divider display={["none", "inherit"]} />
      <Flex flexWrap={"wrap"}>
        <Box width={[1, 1, 1 / 3]} textAlign={"center"} pr={3} pb={3}>
          <GatsbyImage fluid={data.bookCover.childImageSharp.fluid} />
          <ShareButtons {...social} data-testid={"share-buttons"} />
        </Box>
        <Box width={[1, 1, 2 / 3]}>
          <Paragraph>
            To my amazement, after crossing France and Spain, alone, at 64 years
            of age, I discovered I had feet and caught the trekking bug.
          </Paragraph>
          <Paragraph>
            Years later, in my seventies, through various circumstances, I found
            myself trekking in Australia, Nepal, and Spain, both with groups and
            alone, at different times. During these treks, I had more challenges
            to go through physically and this was quite understandable; however,
            to my surprise, there were emotional challenges as well.
          </Paragraph>
          <Paragraph>
            Indeed, I had thought to have resolved all the bumps in my life
            during my first long-distance walk across France and Spain. That was
            not the case; I had to heal the inner-child more so I could be free
            and walk more freely during the rest of my life on this earth.
          </Paragraph>
        </Box>
      </Flex>
      <Flex>
        <Box width={1}>
          <Divider />
          {testimonials.map(({ by, testimony }) => (
            <Testimonial by={by}>{testimony}</Testimonial>
          ))}
          <Divider />
        </Box>
      </Flex>
      <Flex flexWrap={"wrap"}>
        <Box width={[1, 1, 1 / 2]}>
          <Paragraph>
            In Boots to Freedom, you will walk with me and discover my
            experiences trekking with different groups on the Larapinta Trail in
            Australia, graded the 20th hardest trek in the world, Poon Hill - at
            3,210 metres altitude in Nepal, and the Camino Frances in Spain.
            After leaving the groups, I walked, alone, and did more trekking in
            Spain and Portugal. I will share with you my encounters with others.
          </Paragraph>
        </Box>
        <Box width={[1, 1, 1 / 2]} textAlign={"center"} pl={[0, 3]} pb={3}>
          <GatsbyImage fluid={data.nepalMap.childImageSharp.fluid} />
        </Box>
        <Box width={1}>
          <Paragraph>
            The challenges I had met during my lifetime and while trekking,
            physical and emotional, were placed in front of me so I could grow,
            become a better person, and be the person I should be, so I could
            live my life purpose.
          </Paragraph>
          <Paragraph>
            My life has been challenging at times, more than I thought I could
            bear; but eventually, I discovered that it was a blessing in
            disguise.
          </Paragraph>
          <Paragraph>
            I had to learn to be courageous and revisited my life so I could
            look for the blessings I had received. Enjoy the read.
          </Paragraph>
        </Box>
      </Flex>
      <br />
      <Gallery images={formatGraphQLImagesToGallery(data.gallery.edges)} />
    </Layout>
  )
}

export default BootsToFreedom
